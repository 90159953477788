/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom CSS */
.text-brandBlue {
    color: #007bff;
}

.bg-brandBlue {
    background-color: #007bff;
}

.progress-bar {
    height: 0.5rem;
    background-color: #007bff;
}

.checklist-item {
    border: 2px solid #007bff;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #f9f9f9;
}

.task-title {
    color: #1f2937;
    position: relative; /* Ensure the ::before pseudo-element is positioned relative to the task title */
}

.task-title::before {
    content: '';
    display: block;
    width: 0.25rem;
    height: 100%;
    background-color: #007bff;
    position: absolute;
    left: -1.5rem;
    top: 0;
}

.list-disc {
    list-style-type: disc;
    margin-left: 2rem;
}

/* Ensure list items are styled properly */
ul, ol {
    padding-left: 2rem;
}

li {
    margin-bottom: 0.5rem;
}

/* Responsive adjustments */
img {
    max-width: 100%;
    height: auto;
    border-radius: 0.25rem;
}

@media (max-width: 768px) {
    .checklist-item {
        padding: 0.5rem;
    }

    .task-title {
        font-size: 1.5rem;
    }

    .form-checkbox {
        width: 1.25rem;
        height: 1.25rem;
    }

    .list-disc {
        margin-left: 1rem;
    }
}

@media (max-width: 640px) {
    .checklist-item {
        padding: 0.25rem;
    }

    .task-title {
        font-size: 1.25rem;
    }

    .form-checkbox {
        width: 1rem;
        height: 1rem;
    }

    .list-disc {
        margin-left: 0.75rem;
    }

    .progress-bar {
        height: 0.25rem;
    }
    .expanded-width {
        max-width: 100%; /* Allow it to take up more space */
        width: auto;
    }
    
    .task-title::before {
        width: 0.15rem;
        left: -1rem;
    }
}
